<template>
  <div>
    <h1 class="mt-4 mb-4">Cómo conectarte con la App</h1>

    <p>
      Sigue estas instrucciones para poder disfrutar del servidor en una App.
    </p>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5><i class="fas fa-arrow-down"></i> Descárgate la app "Emby"</h5>
            <div class="alert alert-warning" role="alert">
              <h6>Nota importante</h6>
              <p>Debido a que Emby requiere de una suscripción "Emby Premiere" en ciertos dispositivos, la app de Android e iOS está <strong>deshabilitada</strong>. Tienes que usar el navegador siempre que puedas exceptuando aquellos dispositivos en los que el navegador sea incómodo o imposible de usar.</p>
              <p>Por ejemplo, en una TV LG, Samsung o Android TV no queda otra que usar la app, pero en un móvil/tablet Android o iOs usa el navegador.</p>
            </div>
            <hr>
            <img src="../assets/images/emby-logo-grey.png" class="card-img-top img-fluid m-3" style="max-width: 500px">
            <p class="m-3">Busca en la tienda de aplicaciones de tu dispositivo:</p>
            <img src="../assets/images/lg-store-badge.png" class="card-img-top img-fluid m-3" style="max-width: 150px">
            <a href="https://www.amazon.com/Emby-for-Fire-TV/dp/B00VVJKTW8/ref=sr_1_1?ie=UTF8&qid=1548609948&sr=8-1&keywords=emby"><img src="../assets/images/amazon-appstore-badge.png" class="card-img-top img-fluid m-3" style="max-width: 150px"></a>
            <p>
              <button type="button" class="btn m-1 btn-lg btn-outline-warning" onclick="window.open('https://emby.media/community/index.php?/topic/74969-samsung-tizen-smarttv-2015-present-beta-app-install-from-usb','_blank')"><i class="fas fa-external-link-alt"></i> Samsung Smart TV's</button>
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fas fa-tablet-alt"></i> Abre la aplicación</h5>
            <hr>
            <p>Cuando te pida usuario y contraseña busca la opción que pone "Saltar y/o conectar al servidor manualmente".</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fas fa-edit"></i> Introduce estos datos</h5>
            <hr>
            <p>Host, el puerto déjalo vacío y dale a conectar.</p>
            <img src="../assets/images/datos-app.jpg" class="card-img-top img-fluid m-3" style="max-width: 350px">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md card m-3 text-center shadow bg-light">
          <div class="card-body">
            <h5 class="card-title"><i class="fas fa-sign-in-alt"></i> Inicia sesión</h5>
            <hr>
            <p>Con tu usuario y contraseña de Bemag.</p>
            <h5><i class="fas fa-check"></i></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.$store.commit('setPanelLayout')
    }
  }
</script>
